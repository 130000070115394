html, body, #root, #app, #app>div {
  height: 100%;
  overflow: hidden;
}

.App {
  background-color: #0e141b;
  width: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header{
  height: 65px;
  color: white;
  font-weight: 400;
  font-size: 18px;
  background-color: #0e141b;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.header img {
  width: 50px;
  top: 2px;
  position: relative;
}

.content {
  background-color: #0e141b;
  margin: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.links {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  z-index: 1;
}

.single_link{
  transition: all .1s;
  background-color: #141337;
  color: white;
  border-radius: 50px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  width: 300px;
  border: 12px solid #5a58a0;

  -webkit-box-shadow: 0px 5px 32px -2px rgba(90, 88, 160, 1);
  -moz-box-shadow: 0px 5px 32px -2px rgba(90, 88, 160, 1);
  box-shadow: 0px 5px 32px -2px rgba(90, 88, 160, 1);
}

.single_link:hover {
  transition: all .1s;
  font-size: 1.25rem;
}

.single_link p {
  margin-left: 5px;
}

.footer {
  background-color: #1d2935;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffffd9;
  font-size: 14px;
}



.wave-wrapper{
  background-color: #0e141b;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: -171px;
}

.wave-wrapper svg{
  width: 100%;
  height: 100%;
  z-index: 2;
}


a {
  transition: all .3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none; /* no underline */
}

.single_link > a:hover{
  transition: all .3s;
  color: #5263f2;
}

.memoji{
  width: 90px;
  border-radius: 50px;
  z-index: 3;
}

.footer > p {
  z-index: 3;
}

.name {
  color: #ffffff;
  font-family: 'Poppins';
  text-transform: uppercase;
  font-style: oblique;
  font-weight: 1000;
  font-size: 330px;
  position: absolute;
  left: -10%;
  top: 4%;
  opacity: 0.05;

  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lastname {
  color: #ffffff;
  font-family: 'Poppins';
  text-transform: uppercase;
  font-style: oblique;
  font-weight: 1000;
  font-size: 330px;
  position: absolute;
  right: -25%;
  top: 35%;
  opacity: 0.05;

  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media only screen and (max-width: 700px) {
  .name {
    color: #ffffff;
    font-family: 'Poppins';
    text-transform: uppercase;
    font-style: oblique;
    font-weight: 1000;
    font-size: 160px;
    position: absolute;
    left: -9%;
    top: 3%;
    opacity: 0.15;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    user-select: none;
  }
  .lastname {
    color: #ffffff;
    font-family: 'Poppins';
    text-transform: uppercase;
    font-style: oblique;
    font-weight: 1000;
    font-size: 160px;
    position: absolute;
    right: -24%;
    top: 50%;
    opacity: 0.15;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    user-select: none;
  }

  .memoji{
    width: 60px;
    border-radius: 50px;
    margin-bottom: -5px;
  }

  .wave-wrapper{
    margin-bottom: -25px;
  }

  .footer {
    font-size: 12px;
  }

  .single_link {
    border-width: 7px;
    font-size: 1rem;
  }

  .single_link:hover {
    transition: all .1s;
    font-size: 1.2rem;
  }
}
