#root {
    overflow: auto;
}

#container {
    background: #ffffff;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

#content {
    background: #ffffff;
    color: #242424;
    width: 45%;
    min-width: 680px;
    padding: 20px;
    border: 0px solid black;
}

@media (max-width: 768px) {
    #content {
        width: auto;
        min-width: 0;
    }
}

.imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.imageContainer > img, video {
    max-width: 100%;
}

.imageContainer > span {
    color: #6B6B6B;
    font-size: 14px;
    text-align: center;
    font-family: Helvetica;
    width: 95%;
}

.imageContainer > span > a {
    color: #242424;
    font-size: 14px;
    text-align: center;
    font-family: Helvetica;
    font-style: italic;
}

h1 {
    font-size: 42px;
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    margin-bottom: 50px;
}

h2 {
    font-size: 32px;
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    margin-top: 50px;
}

#container > #content > p, ul, li {
    font-family: "Source Serif 4", serif;
    font-size: 20px;
    font-weight: 400;
}

.code {
    font-family: monospace;
    font-size: 16px;
    background: gainsboro;
    padding: 3px;
}

#footer {
    font-family: "Source Serif 4", serif;
    font-size: 14px;
    color: #242424;
}
